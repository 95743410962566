$table-color: $gray-300;
$table-color-state: $gray-300;
.react-bootstrap-table{
  overflow-x:auto !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.react-bs-table .table-bordered {
  border-color: var(--bs-table-border-color);
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: rgb(240, 243, 245);
}

.table.table-striped > tbody > tr > td:nth-child(1), table.table-striped > thead > tr:nth-child(2) > th:nth-child(1) {
  background-color: #20a8d8;
}
