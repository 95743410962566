// Here you can add other styles
.preloader{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &.loader-small{
        border: 4px solid #b0d0d0;
        border-top: 4px solid #00cc9d;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        margin-right: 10px!important;
        margin-top: 2px!important;
        -webkit-animation: spin .8s linear infinite; /* Safari */
        animation: spin .8s linear infinite;
      }
       /* Safari */
       @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
      }

      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
}
.fa-download{
  font-size: 22px;
}
.disable-download-btn{
  color: #aaa;
  cursor: not-allowed;
}
.enable-download-btn{
  cursor: pointer;
}
.search-field{
  border-radius: .25rem;
}

.rw-widget-picker {
  height: 20px !important;
}

.rw-widget-picker>* {
  font-size: 10px !important;
}

.rw-popup-container {
  font-size: 10px !important;
}

.rw-dropdown-list {
  font-size: 10px !important;
}

.alert-success {
  width: 100% !important;
  height: 5% !important;
  font-size: 20px !important;
}

.react-toggle-track-x {
  top: -10px !important;
}
.react-toggle-track-check {
  top: -10px !important;
}

.year-select-to {
  margin-top: -5px;
}

.btn.disabled, .btn:disabled {
  opacity: initial;
}

.subpoena-icon {
  display: block;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
}
